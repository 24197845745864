import { buildSchema, EntityReference } from "@camberi/firecms";

type MarketPlaceType = {
  item: EntityReference;
  position: EntityReference;
};

export const marketPlaceSchema = buildSchema<MarketPlaceType>({
  name: "Marketplace",
  properties: {
    position: {
      title: "Position",
      validation: { required: true },
      dataType: "reference",
      path: "marketplace_positions",
      previewProperties: ["name"],
    },
    item: {
      title: "Item",
      validation: { required: true },
      dataType: "reference",
      path: "items",
      previewProperties: ["name"],
    },
  },
});

type MarketPlacePositionsType = {
  name: string;
  pid: string;
};
export const marketPlacePositionsSchema = buildSchema<MarketPlacePositionsType>(
  {
    name: "Marketplace Positions",
    properties: {
      name: {
        title: "Name",
        description: "Name of position",
        dataType: "string",
        columnWidth: 300,
      },
      pid: {
        title: "Position Id",
        description: "Id of position",
        dataType: "string",
        columnWidth: 300,
      },
    },
  }
);
