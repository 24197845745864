import { buildProperty, buildSchema, EntityReference } from "@camberi/firecms";
import { Button } from "@mui/material";

type Item = {
  rarity: EntityReference;
  name: string;
  trait: EntityReference;
  includedItems: EntityReference[];
  price: number;
  published: boolean;
  description: string;
  cardImage: string;
  layerImage: string;
  devnetMasterMintAddress: string;
  mainnetMasterMintAddress: string;
  license: EntityReference;
};

export const itemSchema = buildSchema<Item>({
  name: "Items",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      description: "Description of item",
      dataType: "string",
      columnWidth: 300,
    },
    price: {
      title: "Price",
      description: "Item price in Solana",
      dataType: "number",
      columnWidth: 120,
    },
    rarity: {
      title: "Rarity",
      validation: { required: true },
      description: "Rarity of item",
      dataType: "reference",
      path: "rarities",
      previewProperties: ["name"],
    },
    trait: {
      title: "Trait",
      validation: { required: true },
      description: "Trait of Item",
      dataType: "reference",
      path: "traits",
      previewProperties: ["name"],
    },
    cardImage: buildProperty({
      title: "Card Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images/cards",
          acceptedFiles: ["image/*"],
        },
      },
      columnWidth: 170,
    }),
    layerImage: buildProperty({
      title: "Layer Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images/layers",
          acceptedFiles: ["image/*"],
        },
      },
      columnWidth: 190,
    }),
    includedItems: {
      dataType: "array",
      title: "Included Items",
      description: "Included Items",
      of: {
        dataType: "reference",
        path: "items",
        previewProperties: ["name"],
      },
    },
    devnetMasterMintAddress: {
      title: "Devnet Master Address",
      validation: { required: false },
      dataType: "string",
      readOnly: true,
    },
    mainnetMasterMintAddress: {
      title: "Mainnet Master Address",
      validation: { required: false },
      dataType: "string",
      readOnly: true,
    },
    license: {
      title: "License",
      validation: { required: true },
      description: "NFT License",
      dataType: "reference",
      path: "licenses",
      previewProperties: ["name"],
    },
    published: ({ values }) =>
      buildProperty({
        title: "Published",
        dataType: "boolean",
      }),
  },
});
