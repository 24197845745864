import { buildProperty, buildSchema } from "@camberi/firecms";

type Collection = {
  name: string;
  description: string;
  image: string;
  devnetMasterMintAddress: string;
  mainnetMasterMintAddress: string;
};

export const collectionsSchema = buildSchema<Collection>({
  name: "Collections",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      description: "Description of collection",
      dataType: "string",
      columnWidth: 300,
    },
    image: buildProperty({
      title: "Collection Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images/collections",
          acceptedFiles: ["image/*"],
        },
      },
      columnWidth: 170,
    }),
    devnetMasterMintAddress: {
      title: "Devnet Master Address",
      validation: { required: false },
      dataType: "string",
      readOnly: true,
    },
    mainnetMasterMintAddress: {
      title: "Mainnet Master Address",
      validation: { required: false },
      dataType: "string",
      readOnly: true,
    },
  },
});
