import { buildSchema, EntityReference } from "@camberi/firecms";

type Trait = {
  name: string;
  description: string;
  collection: EntityReference;
};

export const traitSchema = buildSchema<Trait>({
  name: "Traits",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      description: "Description of trait",
      dataType: "string",
      columnWidth: 300,
    },
    collection: {
      title: "Collection",
      validation: { required: true },
      description: "Collection",
      dataType: "reference",
      path: "collections",
      previewProperties: ["name"],
    },
  },
});
