import { buildSchema } from "@camberi/firecms";

type Rarity = {
  name: string;
  description: string;
  price: number;
  total: number;
};

export const raritySchema = buildSchema<Rarity>({
  name: "Rarities",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      description: "Description of rarity",
      dataType: "string",
      columnWidth: 300,
    },
    price: {
      title: "Price",
      description: "Item price in Solana",
      dataType: "number",
      columnWidth: 120,
    },
    total: {
      title: "Total",
      description: "Total amount available",
      dataType: "number",
      columnWidth: 120,
    },
  },
});
