import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import { itemSchema } from "./schemas/items_schema";
import { raritySchema } from "./schemas/rarities_schema";
import { traitSchema } from "./schemas/traits_schema";
import { collectionsSchema } from "./schemas/collections_schema";
import { licenseSchema } from "./schemas/license_schema";
import {
  marketPlaceSchema,
  marketPlacePositionsSchema,
} from "./schemas/marketplace_schema";

import CoinBoiPath from "./coinboi.png";

import "typeface-rubik";
import "typeface-space-mono";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Setup collections
const itemsCollection = buildCollection({
  path: "items",
  schema: itemSchema,
  name: "Items",
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("admin"),
    create: authController.extra.roles.includes("admin"),
    delete: authController.extra.roles.includes("admin"),
  }),
});

const collectionsCollection = buildCollection({
  path: "collections",
  schema: collectionsSchema,
  name: "Collections",
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("admin"),
    create: authController.extra.roles.includes("admin"),
    delete: authController.extra.roles.includes("admin"),
  }),
});

const marketPlaceCollection = buildCollection({
  path: "marketplace",
  schema: marketPlaceSchema,
  name: "Marketplace",
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("admin"),
    create: authController.extra.roles.includes("admin"),
    delete: authController.extra.roles.includes("admin"),
  }),
});
const marketPlacePositionsCollection = buildCollection({
  path: "marketplace_positions",
  schema: marketPlacePositionsSchema,
  name: "Marketplace Positions",
  textSearchEnabled: true,
  permissions: ({ authController }) => ({
    edit: false,
    create: false,
    delete: false,
  }),
});

const raritiesCollection = buildCollection({
  path: "rarities",
  schema: raritySchema,
  name: "Rarities",
  textSearchEnabled: true,
  permissions: () => ({
    edit: false,
    create: false,
    delete: false,
  }),
});

const traitsCollection = buildCollection({
  path: "traits",
  schema: traitSchema,
  name: "Traits",
  textSearchEnabled: true,
  permissions: () => ({
    edit: true,
    create: false,
    delete: false,
  }),
});

const licenseCollection = buildCollection({
  path: "licenses",
  schema: licenseSchema,
  name: "Licenses",
  textSearchEnabled: true,
  permissions: () => ({
    edit: false,
    create: true,
    delete: false,
  }),
});

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        itemsCollection,
        raritiesCollection,
        traitsCollection,
        marketPlaceCollection,
        marketPlacePositionsCollection,
        collectionsCollection,
        licenseCollection,
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    // Temp allow these user Ids
    const admins = [
      "1ztNFnWujnaqoa80Nq4MWQkRFlW2",
      "Nt3SDWHNugZvHIVhMXHgXcu4y6B2",
      "e7lzjaQ6BAZrK0k3QwQB1pxsesJ2",
      "ufWd9Lm1GQT7QZXMQB1oEz89VoL2",
    ];

    if (!user || (user && !admins.includes(user?.uid))) {
      throw Error(
        "You are not authenticated, please contact an admin for access."
      );
    }

    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: ["admin"],
    });
    authController.setExtra(sampleUserData);
    return true;
  };

  return (
    <FirebaseCMSApp
      name={"CoinBoi CMS"}
      authentication={myAuthenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
      logo={CoinBoiPath}
    />
  );
}
