import { buildSchema } from "@camberi/firecms";

type License = {
  name: string;
  url: string;
};

export const licenseSchema = buildSchema<License>({
  name: "License",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },

    // TODO: Update this to allow uploading directly to arweave
    url: {
      title: "License",
      description: "Decentralized storage location",
      validation: { required: false },
      dataType: "string",
    },
  },
});
